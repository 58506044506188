<template>
  <div class="message-page">
    <h1>Спасибо за покупку!</h1>
    <p>Ваша оплата принята, наш менеджер свяжется с вами в ближайшее время</p>
    <router-link :to="{ name: 'home' }" class="btn btn--lg btn--main-inv">Вернуться на главную</router-link>
  </div>
</template>

<script>
import NOT_FOUND_PAGE from "@/graphql/pages/NotFoundPage.graphql";

export default {
  name: "ThankYouPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: NOT_FOUND_PAGE,
      })
      .then(({ data }) => {
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      })
      .catch(() => {});
  },
  metaInfo: {
    title: "Спасибо!",
    titleTemplate: null,
  },
};
</script>

<style lang="stylus">
.message-page {
  max-width calc(100vw - (100vw - 100%))
  width 100%
  min-height 80vh
  display flex
  justify-content center
  align-items center
  flex-direction column
  gap 16px
  padding 64px 15px
  +below(860px) {
    min-height auto
  }

  h1 {
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: var(--body_color);
    justify-content center
    text-align center
    margin 0
  }

  p {
    font-weight: normal;
    font-size: 0.875em
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    justify-content center
    margin 0
    text-align center
  }
}
</style>
